import React from "react"
import StyledHeading from "./StyledHeading"
import Wrapper from "./Wrapper"
import { useForm as useRHForm } from "react-hook-form"
import { useForm } from "@formspree/react"
import { useIntl } from "gatsby-plugin-react-intl"

//Styled components imports
import ContactFormInput from "./ContactFormInput"
import ContactFormSendButton from "./ContactFormSendButton"
import ContactFormTxtArea from "./ContactFormTxtArea"
import InputWrapper from "./InputWrapper"
import ErrorParagraph from "./ErrorParagraph"
import TxtAreaWrapper from "./TxtAreaWrapper"
import ButtonWrapper from "./ButtonWrapper"
import StateParagraph from "./StateParagraph"

const ContactForm = () => {
  const intl = useIntl()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useRHForm()
  const [state, handleSubmitFS, resetForm] = useForm("xdoboqzw")
  const EMAIL_REGEX =
    //eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const onSubmit = (data, e) => {
    handleSubmitFS(e)

    if (state.errors.length > 0) {
      state.errors.forEach(error => console.log("ERROR: " + error.message))
    }

    setTimeout(() => {
      resetForm()
    }, 4000)
  }

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <StyledHeading>
        {intl.formatMessage({
          id: "contact_form.title",
        })}
      </StyledHeading>
      <InputWrapper>
        <ContactFormInput
          isError={errors.name}
          placeholder={intl.formatMessage({
            id: "contact_form.name",
          })}
          type="text"
          {...register("name", {
            required: intl.formatMessage({
              id: "contact_form.mandatory_field",
            }),
          })}
        />
        {errors.name && <ErrorParagraph>{errors.name.message}</ErrorParagraph>}
      </InputWrapper>
      <InputWrapper>
        <ContactFormInput
          isError={errors.email}
          placeholder={intl.formatMessage({
            id: "contact_form.email",
          })}
          type="email"
          {...register("email", {
            required: intl.formatMessage({
              id: "contact_form.mandatory_field",
            }),
            pattern: {
              value: EMAIL_REGEX,
              message: intl.formatMessage({
                id: "contact_form.valid_email",
              }),
            },
          })}
        />
        {errors.email && (
          <ErrorParagraph>{errors.email.message}</ErrorParagraph>
        )}
      </InputWrapper>
      <TxtAreaWrapper>
        <ContactFormTxtArea
          isError={errors.content}
          placeholder={intl.formatMessage({
            id: "contact_form.message",
          })}
          {...register("content", {
            required: intl.formatMessage({
              id: "contact_form.mandatory_field",
            }),
          })}
        />
        {errors.content && (
          <ErrorParagraph>{errors.content.message}</ErrorParagraph>
        )}
      </TxtAreaWrapper>
      <ButtonWrapper>
        <StateParagraph color="#fff">
          {state.submitting
            ? intl.formatMessage({
                id: "contact_form.sending",
              })
            : state.succeeded
            ? intl.formatMessage({
                id: "contact_form.thank_you",
              })
            : state.errors.length > 0
            ? intl.formatMessage({
                id: "contact_form.error",
              })
            : ""}
        </StateParagraph>
        <ContactFormSendButton
          type="submit"
          value={intl.formatMessage({
            id: "contact_form.send",
          })}
          disabled={state.submitting}
        />
      </ButtonWrapper>
    </Wrapper>
  )
}

export default ContactForm
