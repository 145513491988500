import styled from "styled-components"

const ErrorParagraph = styled.p`
    color: red;
    font-size: 12px;
    position: absolute;
    bottom: -15px;
    left: 12px;
  
  @media (${props => props.theme.mediaQueries.tablet}) {
  }
`

export default ErrorParagraph
