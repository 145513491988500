import styled from "styled-components"

const ContactFormTxtArea = styled.textarea`
  color: ${props => props.theme.colors.text.white};
  background: transparent;
  border: none;
  outline: none;
  resize: none;
  font-size: 16px;
  border: ${props => props.isError ? '1px solid red' : '1px solid white'};
  padding: 10px 10px 5px 10px;
  flex-grow: 1;
`

export default ContactFormTxtArea
