import styled from "styled-components"

const TxtAreaWrapper = styled.div`    
  position: relative;
  display: flex;
  
  @media (${props => props.theme.mediaQueries.tablet}) { 
    grid-column: 1 / span 2;    
  }
`

export default TxtAreaWrapper
