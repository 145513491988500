import styled from "styled-components"

const Wrapper = styled.ul`
  display: grid;

  margin: 16px 32px;
  grid-row-gap: 15px;

  @media (min-width: 620px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 21px;
  }

  @media (min-width: 1350px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export default Wrapper
