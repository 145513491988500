import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby-plugin-react-intl"

const Link = styled(GatsbyLink)`
  color: ${props => props.theme.colors.text.white};
  font-size: 16px;
  text-align: center;
  display: inline-block;

  &:hover {
    filter: brightness(80%);
  }
`

export default Link
