import styled from "styled-components"
import { ChevronDoubleRight } from "@styled-icons/heroicons-solid/ChevronDoubleRight"

const ChevronDoubleRightIcon = styled(ChevronDoubleRight)`
  display: inline;
  width: 23px;
  color: ${props => props.theme.colors.text.darkGrey};
`

export default ChevronDoubleRightIcon
