import styled from "styled-components"

const StateParagraph = styled.p`
    color: ${props => props.color};
    font-size: 12px;
    flex-grow: 1;
    padding: 0 12px;
    text-align: right;
    height: fit-content;
    align-self: center;
  
  @media (${props => props.theme.mediaQueries.tablet}) {    
    font-size: 15px;
  }
`

export default StateParagraph
