import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby-plugin-react-intl"

const TitleLink = styled(GatsbyLink)`
  &:hover {
    text-decoration: underline;
  }
`

export default TitleLink
