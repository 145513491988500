import styled from "styled-components"

const ContactFormSendButton = styled.input`
  box-shadow: none;
  padding-top: 2px;
  padding-bottom: 2px;
  display: block;
  width: 100px;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid #fff;
  background-color: ${props => (props.leave ? "#000000" : "transparent")};
  color: #fff;
  margin-right: ${props => props.enter && "24px"};
  font-weight: 500;
  border-radius: 2px;
  font-size: 20px;
  height: 40px;
  
  &:hover {
    background-color: #a3a3a3;
    color: #000;
  }

  &:disabled {
    background-color: #a3a3a3;
    color: #000;
  }
  
  @media (${props => props.theme.mediaQueries.tablet}) {
    width: 135px;
    font-size: 20px;
  }
`

export default ContactFormSendButton
