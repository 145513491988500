import styled from "styled-components"

const StyledHeading = styled.h3`
    font-weight: 600;
    
  
  @media (${props => props.theme.mediaQueries.tablet}) {    
    grid-column: 1 / span 2;
  }
`

export default StyledHeading

  