import styled from "styled-components"

const Wrapper = styled.form`
  --hor-margin: 100px;
  position: relative;
  display: grid;
  row-gap: 20px;
  width: calc(100% - (var(---hor-margin) * 2));
  height: 400px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 3fr 1fr;
  padding: 20px 0;
  margin-top: 32px;
  
  @media (${props => props.theme.mediaQueries.tablet}) {
    height: 350px;
    padding: 20px 100px;
    column-gap: 40px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 3fr 1fr;
    border: none;
    margin: 0;
    margin-top: 32px;
  }
`

export default Wrapper
