import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"

//React components imports
import Layout from "../../components/Layout"
import Breadcrumbs from "../../components/Breadcrumbs"
import News from "../../components/News"
import ContactForm from "../../components/ContactForm"

//Styled components imports
import Title from "../../shared-styled-components/Title"
import StyledPage from "../../shared-styled-components/StyledPage"

const Page = ({ location, pageContext }) => {
  const intl = useIntl()
  const getPageData = () => {
    if (intl.locale === "en") {
      return pageContext.dataEn
    } else {
      return pageContext.dataCs
    }
  }

  const localePageData = getPageData()
  return (
    <Layout subtitle={localePageData.title}>
      <Breadcrumbs
        data={{
          path: location.pathname.replace("/en", ""),
          breadEnd: localePageData.title,
        }}
      />
      <Title>{localePageData.title}</Title>
      {localePageData.title === "Novinky" || localePageData.title === "News" ? (
        <News articles={localePageData.articles} />
      ) : (
        <>
          <StyledPage
            dangerouslySetInnerHTML={{ __html: localePageData.body }}
          />
          {/* Show contact form for custom tailored page */}
          {(localePageData.title === "Zakázková výroba" ||
            localePageData.title === "Custom tailored") && <ContactForm />}
        </>
      )}
    </Layout>
  )
}

export default Page
