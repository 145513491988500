import styled from "styled-components"

const StyledPage = styled.div`
  h3 {
    margin-bottom: 16px;
    font-weight: 600;
  }

  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  p {
    margin-bottom: 16px;
  }

  ul {
    list-style-type: disc;
    margin-bottom: 16px;

    li {
      font-size: 16px;
    }
  }

  * {
    color: white !important;
    background: black !important;
  }
`

export default StyledPage
