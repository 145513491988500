import styled from "styled-components"

const InputWrapper = styled.div`    
  position: relative;
  display: flex;
  
  @media (${props => props.theme.mediaQueries.tablet}) {
  }
`

export default InputWrapper
