import React from "react"

//Styled components imports
import Wrapper from "./Wrapper"
import Container from "./Container"
import Image from "./Image"
import ImageWrapper from "./ImageWrapper"
import Date from "./Date"
import Title from "./Title"
import Excerpt from "./Excerpt"
import LinkWrapper from "./LinkWrapper"
import Link from "./Link"
import TitleLink from "./TitleLink"
import ChevronDoubleRightIcon from "./ChevronDoubleRightIcon"

const NewsCard = ({ image, date, title, excerpt, handle }) => {
  return (
    <Wrapper>
      <ImageWrapper to={`/novinky/${handle}`}>
        <Image src={image.url} alt={image.altText} />
      </ImageWrapper>

      <Container>
        <Date>{date}</Date>
        <Title>
          <TitleLink to={`/novinky/${handle}`}>{title}</TitleLink>
        </Title>
        <Excerpt>{excerpt}</Excerpt>
      </Container>
      <LinkWrapper>
        <Link to={`/novinky/${handle}`}>
          Celý článek <ChevronDoubleRightIcon />
        </Link>
      </LinkWrapper>
    </Wrapper>
  )
}

export default NewsCard
