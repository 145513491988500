import styled from "styled-components"

const ContactFormInput = styled.input`
  height: 40px;
  color: ${props => props.theme.colors.text.white};
  background: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  border: ${props => props.isError ? '1px solid red' : '1px solid white'};
  padding: 5px 10px;
  flex-grow: 1;

  @media (${props => props.theme.mediaQueries.tablet}) {
  }
`

export default ContactFormInput
