import styled from "styled-components"

const Wrapper = styled.li`
  background: ${props => props.theme.colors.background.card};
  display: flex;
  flex-direction: column;
  border-radius: 2px;
`

export default Wrapper
