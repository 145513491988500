import styled from "styled-components"

const ButtonWrapper = styled.div`    
  position: relative;
  display: flex;
  align-content: center;
  width: 100%;
  
  @media (${props => props.theme.mediaQueries.tablet}) {
    grid-column: 1 / span 2;
  }
`

export default ButtonWrapper
