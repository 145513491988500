import React from "react"

//React components imports
import NewsCard from "./NewsCard"

//Styled components imports
import Wrapper from "./Wrapper"

const News = ({ articles }) => {
  console.log("ARTICLEs", articles)
  return (
    <Wrapper>
      {articles?.map(article => {
        return (
          <NewsCard
            key={article.node.id}
            image={article.node.image}
            date={new Date(article.node.publishedAt).toLocaleDateString(
              "cs-CZ",
              {
                year: "numeric",
                month: "long",
                day: "numeric",
              }
            )}
            title={article.node.title}
            excerpt={article.node.excerpt}
            handle={article.node.handle}
          />
        )
      })}
    </Wrapper>
  )
}

export default News
