import styled from "styled-components"
// import { GatsbyImage } from "gatsby-plugin-image"

const Image = styled.img`
  border-radius: 2px;
  transition: transform 0.3s ease-in;
  cursor: pointer;
  height: 220px;

  width: 100%;
  object-fit: cover;

  &:hover {
    transform: scale(1.1);
  }

  @media (${props => props.theme.mediaQueries.desktop}) {
    height: 280px;
  }
`

export default Image
